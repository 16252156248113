/**
 *Project:Traceability Platform
 *File:sign-up
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { useEffect } from "react";
import cn from "classnames";

import { Box, Button, Text } from "@components/common";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPasswordAction } from "store/actions/authActions";
import FormProvider from "components/forms/FormProvider";
import TextWrapper from "@forms/TextWrapper";

interface Props {
}

export interface NewPasswordRequest {
  password: string,
  passwordResetToken?: string,
  username: string
}

const NewPassword: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const queryParams = new URLSearchParams(window.location.search);

  const { isLoading } = useAppSelector((state) => state.loading);
  const { toast: { variant } } = useAppSelector((state) => state.common);

  useEffect(() => {
    if (variant === "success") {
      navigate("/auth/login");
    }
  }, [variant]);

  const methods = useForm({
    mode: "onTouched",
    resolver: yupResolver(Yup.object().shape({
      password: Yup.string().required("Password is required"),
      confirmPassword: Yup.string().oneOf([Yup.ref("password")], "Passwords must match"),
    })),
    defaultValues: { password: "", confirmPassword: "", username: "" },
  });


  const {
    reset,
    handleSubmit,
    formState: {
      isValid,
    },
  } = methods;

  function onSubmit(payload: { password: string, confirmPassword: string, username: string }): void {

    dispatch(resetPasswordAction({
      password: payload.password,
      passwordResetToken: queryParams.get("resetToken") ?? "",
      username: queryParams.get("username") ?? "",
    }));
  }

  return (
    <Box className={"authForm reset-password"}>
      <Text Component="h2" className={"header"}>
        Enter New Password
      </Text>
      <Box col className={"content w-full"}>
        <Text Component="h2" className={"subtitle"}>
          Kindly input your new password.
        </Text>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <TextWrapper
            className={cn("input mb-8 !bg-gray-100 !text-gray-500")}
            label={"Email"}
            name="username"
            type="email"
            disabled
            value={queryParams.get("username") ?? ""}
          />
          <TextWrapper
            className={cn("input mb-8")}
            type={"password"}
            label={"New Password"}
            placeholder={"Enter your password"}
            name="password"
          />
          <TextWrapper
            className={cn("input")}
            type={"password"}
            label={"Confirm Password"}
            placeholder={"Re enter your password"}
            name="confirmPassword"
          />
          <Button
            label={isLoading ? "Processing..." : "Reset password"}
            pill
            className={cn("input button mt-10")}
            type={"submit"}
            disabled={!isValid}
          />
        </FormProvider>
      </Box>
    </Box>
  );
};

export default NewPassword;
