/**
 *Project:Traceability Platform
 *File:loader
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import s from "./index.module.css";
import cn from "classnames";
import { KgFrame } from "@components/Icons";
import { useAppSelector } from "hooks/appHooks";

type Props = {
  message?: string;
};

const Loader: React.FC<Props> = (props) => {
  const { user } = useAppSelector((state) => state.auth);
  if (!user && !window.location.pathname.toLowerCase().includes("/auth") && !window.location.pathname.toLowerCase().split("/").includes("invitation-response")) {
    window.location.pathname = "/auth/login";
  }

  return (
    <div className={s.root}>
      <KgFrame
        className={cn("max-w-[15rem] h-fit w-[80%]", {
          "w-[50%]": props.message,
        })}
      />
      {props.message ? (
        <span className="text-primary text-2xl">{props.message}</span>
      ) : (
        <svg
          className="animate-spin h-[3.5rem] w-[3.5rem] text-primary"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
    </div>
  );
};

export default Loader;
