/**
 * Project: tracebility-dashboard
 * File: commonReducer
 * Created by Pennycodes on 9/27/2022.
 * Copyright tracebility-dashboard
 */
import createReducer from "hooks/createReducer";
import * as types from "../actions/types";
import { ICommonState } from "models/store/common";
import { ICommonResponse } from "models/query/common";

const initialState: ICommonState = {
  visible: false,
  message: "",
  type: "",
  toast: {
    variant: "info",
    message: "",
    position: "top-right",
  },
  status: 100,
  data: [],
};


const commonReducer = createReducer(initialState, {
  [types.SHOW_TOAST](state: ICommonState, action: { message: string, variant: string }) {
    return {
      ...state,
      visible: true,
      type: types.SHOW_TOAST,
      toast: {
        message: action.message,
        variant: action.variant,
      },
    };
  },
  [types.HIDE_TOAST](state: ICommonState) {
    return {
      ...state,
      visible: false,
      type: "",
      toast: {
        message: "",
        variant: "info",
      },
    };
  },
  [types.COMMON_RESPONSE](state: ICommonState, action: ICommonResponse) {
    return { ...state, status: action.status, data: action.message };
  },
  [types.COMMON_FAILED](state: ICommonState, action: ICommonResponse) {
    return { ...state, status: action.status, data: action.message };
  },
});
export default commonReducer;
