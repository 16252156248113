/**
 * Project: tracebility-dashboard
 * File: authActions
 * Created by Pennycodes on 9/27/2022.
 * Copyright tracebility-dashboard
 */
import { ICommonResponse } from "models/query/common";
import * as types from "./types";
import auth from "models/query/auth";
import { NewPasswordRequest } from "@forms/buyer/auth/reset-password/NewPassword";

export function requestLogin(username: string, password: string) {
  return {
    type: types.LOGIN_REQUEST,
    username,
    password,
  };
}

export function initializeApp(isAuthenticated: boolean, user: any) {
  return {
    type: types.INITIALIZE,
    isAuthenticated,
    user,
  };
}

export function loginFailed(body: ICommonResponse) {
  return {
    type: types.LOGIN_FAILED,
    ...body,
  };
}

export function onLoginResponse(response: auth.RootObject) {
  return {
    type: types.LOGIN_RESPONSE,
    ...response,
  };
}

export function loginSuccessful() {
  return {
    type: types.LOGIN_LOADING_ENDED,
  };
}

export function logOut() {
  return {
    type: types.LOG_OUT,
  };
}

export function forgotPassword(email: string) {
  return {
    type: types.FORGOT_PASSWORD,
    email,
  };
}

export function forgotPasswordFailed(body: ICommonResponse) {
  return {
    variant: "error",
    type: types.SHOW_TOAST,
    ...body,
  };
}

export function forgotPasswordSuccessful(email: string) {
  return {
    variant: "success",
    type: types.SHOW_TOAST,
    message: `A password reset email has been sent to [${email}]`,
  };
}

export function resetPasswordAction(payload: NewPasswordRequest) {
  return {
    type: types.RESET_PASSWORD,
    payload,
  };
}

export function resetPasswordFailed(body: ICommonResponse) {
  return {
    variant: "error",
    type: types.SHOW_TOAST,
    ...body,
  };
}

export function resetPasswordSuccessful() {
  return {
    variant: "success",
    type: types.SHOW_TOAST,
    message: "Password reset successfully",
  };
}

