/**
 * Project: tracebility-dashboard
 * File: index
 * Created by pennycodes on 18/10/2022.
 * Copyright tracebility-dashboard
 */
import { useEffect } from 'react';
// next
import { useNavigate, useLocation } from 'react-router-dom';
import {useAppSelector} from "hooks/appHooks";
// config

export default function Index() {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { isAuthenticated, user } = useAppSelector(
        (state) => state.auth
    );


    useEffect(() => {
        if (pathname === '/') {
            if (isAuthenticated && user) {
                navigate("/app/dashboard", { replace: true });
            } else
                navigate('/auth/login', { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return null;
}
