/**
 * Project: tracebility-dashboard
 * File: authSaga
 * Created by pennycodes on 19/10/2022.
 * Copyright tracebility-dashboard
 */
import {call, put, takeEvery} from 'redux-saga/effects';
import * as authActions from '../actions/authActions';
import * as commonActions from '../actions/commonActions';
import * as types from '../actions/types';
import {forgotPassword, loginService, resetPassword} from 'services/auth';
import Axios, {AxiosError, AxiosResponse} from "axios";
import {setSession} from 'utils/jwt';
import {ICommonResponse} from "@models/query/common";
import auth from "@models/query/auth";


/**
 * User Password Login Saga
 * @param payload
 * @returns {IterableIterator<*>}
 */

function* loginAsync(payload: ReturnType<typeof authActions.requestLogin>): Generator<any, void, AxiosResponse["data"]> {

    yield put(commonActions.enableLoader());
    try {
        const response = yield call(loginService, payload.username, payload.password);

        if (response.status === 200) {
            const data: auth.RootObject = response.data.data[0];
            setSession(data.authPayload.token);

            yield put(authActions.onLoginResponse(data));
            yield put(commonActions.disableLoader());
        } else {
            yield put(authActions.loginFailed(response.data));
            yield put(commonActions.disableLoader());
        }
    } catch (e) {
        let response: ICommonResponse = {
            status: 422,
            message: "An unknown error occurred",
        };
        if (Axios.isAxiosError(e)) {
            const err = e as AxiosError

            if (err.response) {
                // @ts-ignore
                response.status = err.response.data.status
                // @ts-ignore
                response.message = err.response.data.message
            } else {
                response.status = Number(err?.code)
                response.message = err?.message
            }
        } else {
            if (e instanceof Error) {
                response.status = 419;
                response.message = e.message;
            }
        }

        yield put(authActions.loginFailed(response));
        yield put(commonActions.disableLoader());
    }
}

function* logoutAsync() {
    setSession('');
}

function* forgotPasswordAsync(payload: ReturnType<typeof authActions.forgotPassword>): Generator<any, void, AxiosResponse["data"]> {
    yield put(commonActions.enableLoader());
    try {
        const response = yield call(forgotPassword, payload.email);
        if (response.status === 200) {
            yield put(commonActions.disableLoader());
            yield put(authActions.forgotPasswordSuccessful(payload.email))
        } else {
            yield put(authActions.forgotPasswordFailed(response));
            yield put(commonActions.disableLoader());
        }
    } catch (e) {
        let response: ICommonResponse = {
            status: 422,
            message: "An unknown error occurred",
        };
        if (Axios.isAxiosError(e)) {
            const err = e as AxiosError

            if (err.response) {
                // @ts-ignore
                response.status = err.response.data.status
                // @ts-ignore
                response.message = err.response.data.message
            } else {
                response.status = Number(err?.code)
                response.message = err?.message
            }
        } else {
            if (e instanceof Error) {
                response.status = 419;
                response.message = e.message;
            }
        }

        yield put(authActions.forgotPasswordFailed(response));
        yield put(commonActions.disableLoader());
    }
}

function* resetPasswordAsync(args: ReturnType<typeof authActions.resetPasswordAction>): Generator<any, void, AxiosResponse["data"]> {

    yield put(commonActions.enableLoader());
    try {
        const response = yield call(resetPassword, args.payload);
        console.log(response);
        if (response.status === 200) {
            yield put(authActions.resetPasswordSuccessful());
            yield put(commonActions.disableLoader());
        } else {const data: auth.RootObject = response.data.data[0];
            setSession(data.authPayload.token);

            yield put(authActions.resetPasswordFailed(response.data));
            yield put(commonActions.disableLoader());
        }
    } catch (e) {
        let response: ICommonResponse = {
            status: 422,
            message: "An unknown error occurred",
        };
        if (Axios.isAxiosError(e)) {
            const err = e as AxiosError

            if (err.response) {
                // @ts-ignore
                response.status = err.response.data.status
                // @ts-ignore
                response.message = err.response.data.message + "." + err?.response?.data?.errors?.reduce((acc, curr) => acc + curr.message + ". ", " ")
            } else {
                response.status = Number(err?.code)
                response.message = err?.message
            }
        } else {
            if (e instanceof Error) {
                response.status = 419;
                response.message = e.message;
            }
        }

        yield put(authActions.resetPasswordFailed(response));
        yield put(commonActions.disableLoader());
    }
}


export default function* authSaga() {
    yield takeEvery(types.LOGIN_REQUEST, loginAsync);
    yield takeEvery(types.LOG_OUT, logoutAsync);
    yield takeEvery(types.FORGOT_PASSWORD, forgotPasswordAsync);
    yield takeEvery(types.RESET_PASSWORD, resetPasswordAsync);
}
