/**
 * Project: tracebility-dashboard
 * File: auth
 * Created by pennycodes on 19/10/2022.
 * Copyright tracebility-dashboard
 */

import apiClient from "./requests";
import ApiConfig from "./endpoints";
import { NewPasswordRequest } from "@forms/buyer/auth/reset-password/NewPassword";

export function loginService(username: string, password: string) {
  return apiClient.post(ApiConfig.AUTH.LOGIN, { username, password });
}

export function forgotPassword(email: string) {
  return apiClient.get(`${ApiConfig.AUTH.FORGOT_PASSWORD}?username=${encodeURIComponent(email)}`);
}

export function resetPassword(payload: NewPasswordRequest) {
  return apiClient.post(ApiConfig.AUTH.RESET_PASSWORD, payload);
}