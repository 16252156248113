/**
 *Project:Traceability Platform
 *File:sign-up
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { Box, Button, Text, Textfield } from "@components/common";
import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { FORGOT_PASSWORD, SHOW_TOAST } from "store/actions/types";
import { toast } from "react-toastify";

interface Props {
}

const Default: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>("");

  const handleContinue = () => {
    dispatch({ type: FORGOT_PASSWORD, email });
  };

  return (
    <Box className={"authForm reset-password"}>
      <Text Component="h2" className={"header"}>
        Forgot Your Password?
      </Text>
      <Box col className={"content"}>
        <Text Component="h2" className={"subtitle"}>
          Enter your primary contact and we’ll send you instructions on how to
          reset your password.
        </Text>
        <Textfield
          className={cn("input")}
          type={"email"}
          label={"Email or Phone"}
          placeholder={"Enter email address or phone number"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          label={"Continue"}
          pill
          className={"button mt-5 lg:mt-16"}
          onClick={() => handleContinue()}
        />
      </Box>
    </Box>
  );
};

export default Default;
